import axios from "axios";
import { marked } from "marked";
import Noty, { Type } from "noty";

import { components as OpenAPI } from "@/autogen/openapi";
import Urls from "@/autogen/urls";
import { Newsletter } from "@/types/newsletter";
import { Context } from "@/types/preview";

const debounce = function <U>(
  func: (...args: any[]) => Promise<U> | U,
  wait: number,
  immediate: boolean = false
): (...args: any[]) => void {
  var timeout: ReturnType<typeof setTimeout> | null;

  return function executedFunction() {
    // @ts-ignore
    var context = <any>this;
    var args = arguments;

    var callNow = immediate && !timeout;

    var later = function () {
      timeout = null;
      if (!callNow) func.apply(context, Array.from(args));
    };

    if (timeout) {
      // @ts-ignore
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, Array.from(args));
  };
};

const notify = function (text: string, type: Type) {
  new Noty({
    text,
    type,
    progressBar: false,
  }).show();
};

export type RenderedMarkdown = Record<Context, string> & { error?: string };

// Needs to map to the keys of `TRANSACTIONAL_EMAIL_KEY_TO_TRANSACTIONAL_EMAIL`.
export type TransactionalEmail =
  | "confirmation"
  | "gift"
  | "premium_welcome"
  | "automation_notification";

const asynchronouslyRenderMarkdown = async (
  subject: string,
  text: string,
  id?: string,
  newsletter?: Newsletter,
  template?: OpenAPI["schemas"]["NewsletterEmailTemplate"],
  transactionalEmail?: TransactionalEmail
): Promise<RenderedMarkdown> => {
  try {
    const result = await axios.post(Urls["render"](), {
      text,
      subject,
      id,
      newsletter,
      template,
      transactional_email: transactionalEmail,
    });
    return {
      email: result.data.result,
      email_free: result.data.result_free,
      web: result.data.web,
    };
  } catch (error: any) {
    return {
      email: "",
      email_free: "",
      web: "",
      error: error.response.data.message,
    };
  }
};

const asynchronouslyRenderSubPage = async (
  newsletter: Newsletter
): Promise<RenderedMarkdown> => {
  try {
    const result = await axios.post(Urls["render-subscribe-template"](), {
      newsletter,
    });
    return {
      web: result.data.result,
      email: "",
      email_free: "",
    };
  } catch (error: any) {
    return {
      web: "",
      email: "",
      email_free: "",
      error: error.response ? error.response.data.message : "Unknown error",
    };
  }
};

// https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
const VALID_HREF_REGEXES = [
  /mailto:([^]*)/,
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  /{{.*}}/,
];
const isValidHref = (url: string): boolean =>
  VALID_HREF_REGEXES.filter((r) => r.test(url)).length > 0;

const renderMarkdownWithAllLinksAsTargetBlank = (markdownString: string) => {
  // Create a custom renderer to always use target=_blank.
  // Stolen from: https://github.com/chjj/marked/issues/655
  const renderer = new marked.Renderer();
  renderer.link = (href, title, text) =>
    `<a target="_blank" href="${href}" title="${title}">${text}</a>`;

  return marked(markdownString, { renderer });
};

// Given a hex string, e.g. #0069FF
const getTextColorForBackground = (hex: string) => {
  // Remove the #, leaving you with 0069FF.
  const rawHex = hex.replace("#", "");

  // Each two characters represents a color channel in the RGB space:
  // - R = 00
  // - G = 69
  // - B = FF
  const parseHex = (startPosition: number) =>
    parseInt(rawHex.substr(startPosition, 2), 16);

  // So we extract those channels.
  const [r, g, b] = [0, 2, 4].map(parseHex);

  // Convert theme using a YIQ formula:
  // https://en.wikipedia.org/wiki/YIQ#From_RGB_to_YIQ
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // YIQ space is between 0 and 255:
  // If its above average (> 128), then it's light, so we want dark text.
  // If its below average (< 128), then it's dark, so we want light text.
  return yiq >= 128 ? "black" : "white";
};

// https://stackoverflow.com/a/2117523
const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const fetchCookie = (key: string): string | null => {
  return document.cookie.split(/; */).reduce((obj, str) => {
    if (str === "") return obj;
    const eq = str.indexOf("=");
    const key = eq > 0 ? str.slice(0, eq) : str;
    let val = eq > 0 ? str.slice(eq + 1) : null;
    if (val != null)
      try {
        val = decodeURIComponent(val);
      } catch (ex) {
        /* pass */
      }
    obj[key] = val;
    return obj;
  }, {} as { [key: string]: string | null })[key];
};

const diffTwoObjects = <A>(a: A, b: A, relevantKeys: (keyof A)[]) => {
  const diff: {
    [key in keyof A]?: A[key];
  } = {};
  relevantKeys.forEach((key) => {
    if (JSON.stringify(a[key]) !== JSON.stringify(b[key])) {
      diff[key] = b[key];
    }
  });
  return diff;
};

/**
 * Checks if an array contains at least one valid non-empty string.
 * @example
 * containsValidString(["", ""]); // false
 * containsValidString([""]); // false
 * containsValidString(["", "valid"]); // true
 */
const containsValidString = (arr: string[]): boolean => {
  return arr.some(
    (answer) => typeof answer === "string" && answer.trim() !== ""
  );
};

/**
 * Removes empty values from an array of strings.
 * @example
 * removeEmptyValuesFromArray(["", "hello", "", "world"]); // Output: ["hello", "world"]
 */
const removeEmptyValuesFromArray = (arr: string[]): string[] => {
  return arr.filter((value) => value.trim() !== "");
};

export {
    asynchronouslyRenderMarkdown,
    asynchronouslyRenderSubPage,
    containsValidString,
    debounce,
    diffTwoObjects,
    fetchCookie,
    getTextColorForBackground,
    isValidHref,
    notify,
    removeEmptyValuesFromArray,
    renderMarkdownWithAllLinksAsTargetBlank,
    uuid
};
